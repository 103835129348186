import firebase from 'firebase/app'
import 'firebase/firestore'

const trackDefaults = {
  points: [],
  lat: 0,
  lng: 0,
  zoom: 17
}

const findClosestTrack = async (lat, lng) => {
  try {
    const response = await fetch(
      `${process.env.GATSBY_TYPESENSE_PROTOCOL}://${
        process.env.GATSBY_TYPESENSE_HOST
      }${
        process.env.GATSBY_TYPESENSE_PORT == "443"
          ? ""
          : `:${process.env.GATSBY_TYPESENSE_PORT}`
      }/collections/tracks/documents/search?q=*&query_by=name&sort_by=location(${lat},${lng}):asc&per_page=1`,
      {
        headers: {
          "X-TYPESENSE-API-KEY": `${process.env.GATSBY_TYPESENSE_READ_API}`,
        },
      }
    )
    const data = await response.json()
    const closestTrack = data?.hits[0]?.document

    if (!closestTrack) {
      throw new Error('No tracks found near the start/finish point. The racing line will be created without track association.')
    }

    // Calculate distance to check if within 10km
    const distance = getDistance(lat, lng, closestTrack.location[0], closestTrack.location[1])
    if (distance > 10) {
      throw new Error(`Closest track "${closestTrack.name}" is ${Math.round(distance)}km away. The racing line will be created without track association.`)
    }

    return closestTrack
  } catch (error) {
    if (error.message.includes('track')) {
      throw error // Re-throw our custom errors
    }
    console.error('Error finding closest track:', error)
    throw new Error('Failed to search for nearby tracks. The racing line will be created without track association.')
  }
}

// Haversine formula to calculate distance between two points
const getDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371 // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1)
  const dLon = deg2rad(lon2 - lon1)
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
  return R * c
}

const deg2rad = (deg) => {
  return deg * (Math.PI/180)
}

export const createDoc = async (trackValues, currentUser, props = {}) => {
  const linesDb = firebase.firestore().collection(`lines`)
  const { name = '', placeId = '', url = '', vehicle = '', vehicleId = '', telemetryId = '', lapTime = null } = props
  const t = { ...trackDefaults, ...trackValues }

  try {
    const doc = linesDb.doc()
    await doc.set({
      name,
      placeId,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      location: new firebase.firestore.GeoPoint(+t.lat, +t.lng),
      url,
      zoom: t.zoom,
      points: t.points,
      user: currentUser.uid,
      likes: [],
      visibility: 'public',
      isCloseDetected: false,
      vehicle,
      vehicleId,
      telemetryId,
      lapTime
    }, { merge: true })
    
    console.log("Document created with id: ", doc.id)
    return doc.id
  }
  catch(error) {
    console.error("Error creating document: ", error);
    throw new Error('Failed to save the racing line. Please try again.')
  }
}

export const onImportLine = async ({ points, user, carInfo, telemetryId } = {}) => {
  if (!points?.length) {
    throw new Error('No points received from iRacing. Please try the import again.')
  }

  console.log(`Received ${points.length} points from iRacing import`)
  
  try {
    // Convert iRacing points to our format
    const newPoints = points.map((point, index) => {
      if (!point?.lat || !point?.lng) {
        console.error('Invalid point data:', point)
        throw new Error('Invalid point data received from iRacing. Please try the import again.')
      }

      // Determine point type based on inputs
      let type
      
      // If any braking at all, it's a brake point
      if (point.brake > 0) {
        type = 'brake'
      }
      // If no braking but throttle is applied, it's acceleration
      else if (point.throttle > 0) {
        type = 'acc'
      }
      // If no braking and no throttle, it's coasting
      else {
        type = 'coast'
      }
      
      return {
        id: `iracing-${index}`,
        lat: point.lat,
        lng: point.lng,
        type,
        selected: false,
        speed: point.speed,
        rpm: point.rpm,
        gear: point.gear,
        throttle: point.throttle,
        brake: point.brake,
        dist: point.dist,
        pct: point.pct
      }
    })

    console.log(`First converted point:`, newPoints[0])
    
    // Try to find the closest track to the start/finish point
    const startPoint = newPoints[0]
    let closestTrack = null
    let trackError = null
    
    try {
      closestTrack = await findClosestTrack(startPoint.lat, startPoint.lng)
    } catch (error) {
      trackError = error
    }

    // Create new track with imported points
    const newTrack = {
      ...trackDefaults,
      points: newPoints,
      lat: startPoint.lat,
      lng: startPoint.lng,
      zoom: 17
    }
    
    // If we found a close track, use its details
    const docProps = {
      name: closestTrack ? closestTrack.name : 'Imported Racing Line',
      placeId: closestTrack?.placeId || '',
      url: closestTrack?.url || '/racing-line-editor',
      vehicle: carInfo?.carType || '',
      vehicleId: carInfo?.vehicleId || '',
      telemetryId: telemetryId || '',
      lapTime: points[0]?.lapTime || null
    }
    
    console.log('Creating document with car info:', { vehicle: carInfo?.carType, vehicleId: carInfo?.vehicleId, telemetryId, lapTime: points[0]?.lapTime })
    const lineId = await createDoc(newTrack, user, docProps)
    if (!lineId) {
      throw new Error('Failed to create the racing line. Please try again.')
    }

    // If there was a non-fatal track error, include it in the response
    if (trackError) {
      return { lineId, newTrack, url: docProps.url, warning: trackError.message }
    }
    
    return { lineId, newTrack, url: docProps.url }
  } catch (error) {
    console.error('Import error:', error)
    throw error
  }
} 