import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'

// Function to process iRacing telemetry data and extract position points
export const processIRacingTelemetry = async (buffer) => {
    try {
        // Get the current user
        const user = firebase.auth().currentUser
        if (!user) {
            throw new Error(`User not authenticated`)
        }

        // Create a reference to the file in Firebase Storage
        const storage = firebase.storage()
        const timestamp = Date.now()
        const fileRef = storage.ref(`telemetry/${user.uid}/${timestamp}.ibt`)
        
        // Upload the file and get its download URL in one go
        const uploadTask = await fileRef.put(buffer)
        const downloadUrl = await uploadTask.ref.getDownloadURL()
        
        // Call the Cloud Function with the download URL
        const functions = firebase.functions()
        if (process.env.NODE_ENV === `development`) {
            functions.useEmulator(`localhost`, 5001)
        }
        
        const processTelemetry = functions.httpsCallable(`processTelemetry`)
        const result = await processTelemetry({ downloadUrl })
        
        // Clean up: delete the file from storage
        await fileRef.delete()
        
        // Validate the result data
        if (!result.data) {
            throw new Error(`No data received from iRacing. Please try the import again.`)
        }
        
        if (!result.data.points || !Array.isArray(result.data.points)) {
            throw new Error(`No valid points received from iRacing. Please try the import again.`)
        }
        
        // Return all data including track info, points, and lap info
        return {
            trackInfo: result.data.trackInfo,
            points: result.data.points,
            allLaps: result.data.allLaps.map(lap => ({
                ...lap,
                formattedTime: lap.formattedTime || formatLapTime(lap.lapTime),
                points: lap.points.map(point => ({
                    lat: point.lat,
                    lng: point.lng,
                    alt: point.alt,
                    speed: point.speed,
                    rpm: point.rpm,
                    gear: point.gear,
                    throttle: point.throttle,
                    brake: point.brake,
                    dist: point.dist,
                    pct: point.pct,
                }))
            })),
            lap: result.data.lap,
            id: result.data.id
        }
    } catch (error) {
        console.error(`Error processing iRacing telemetry:`, error)
        throw error
    }
}

// Function to sample points to reduce data density
export const samplePoints = (points, sampleRate = 10) => points.filter((_, index) => index % sampleRate === 0)

// Function to validate iRacing telemetry data
export const validateIRacingData = (points) => {
    if (!Array.isArray(points)) {
        throw new Error(`Invalid data format: expected array of points`)
    }
    
    if (points.length === 0) {
        throw new Error(`No valid points found in telemetry data`)
    }
    
    points.forEach((point, index) => {
        if (!point.lat || !point.lng) {
            throw new Error(`Invalid point at index ${index}: missing lat/lng`)
        }
        
        if (typeof point.lat !== `number` || typeof point.lng !== `number`) {
            throw new Error(`Invalid point at index ${index}: lat/lng must be numbers`)
        }
    })
    
    return true
} 