import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';

import { FirebaseContext } from './Firebase'
import 'firebase/firestore'
import 'firebase/analytics'
import LineThumb from './LineThumb'
import IRacingImport from './IRacingImport'
import ProfileThumb from './ProfileThumb'
import LikeButton from './LikeButton'
import arrow from '../../images/arrow.svg'
import { onImportLine } from '../../utils/lineUtils'
import Notification from '../common/Notification'

export default (props) => {
  const auth = useContext(FirebaseContext)
  const firebase = auth.app
  const { requireAuth } = auth
  const linesDb = firebase.firestore().collection(`lines`)
  const [lines, setLines] = useState(null)
  const [importError, setImportError] = useState(null)
  const [importWarning, setImportWarning] = useState(null)
  const {
        wrapperClass = "post-feed horizontal-feed",
        publicLinesOnly = true, 
        closedLinesOnly = true, 
        oneRacingLinePerTrack = true,
        showProfilePhoto = true,
        showVisibility = false,
        showLike = false,
        alwaysShowNewLine = false,
        filterTrack,
        filterUser, 
        limit = 5,
        onNewLine = () => location.href = "/racing-line-editor",
        setLine,
        onImport = async ({ points, error, carInfo, telemetryId } = {}) => {
          if (error) {
            setImportError(error.message || 'Failed to process iRacing file')
            return
          }
          try {
            const result = await onImportLine({ 
              points, 
              user: auth.user, 
              carInfo,
              telemetryId
            })
            if (result.lineId) {
              if (result.warning) {
                setImportWarning(result.warning)
              }
              window.location.href = `${result.url}/r/${result.lineId}`
            } else {
              setImportError('Failed to create racing line. Please try again.')
            }
          } catch (error) {
            console.error('Import error:', error)
            setImportError(error.message || 'Failed to import racing line. Please try again.')
          }
        }
    } = props

  useEffect(() => {
    load()
    console.log("props changed: ", props)
  }, [filterTrack, filterUser])

  const load = async () => {
    try {

        let query = linesDb
        if(publicLinesOnly) query = addWhereToQuery(query, ["visibility", "==", "public"])
        if(closedLinesOnly) query = addWhereToQuery(query, ["isCloseDetected","==", true])
        if(filterUser) query = addWhereToQuery(query, ["user", "==", filterUser])
        if(filterTrack) query = addWhereToQuery(query, ["name", "==", filterTrack])
        const linesDbRes = await query.orderBy("created", "desc")
                .limit(+limit)
                .get()

        let lines = linesDbRes.docs.map(doc => {
            return { id: doc.id, ...doc.data() }
        })

        if(oneRacingLinePerTrack) lines = lines.filter((value, index, array) => 
                                                                index === 0 || value.name != array[index-1].name)
        setLines(lines)
        console.log("Lines found: ", lines)
    }
    catch(error) {
        console.error("Error loading document: ", error);
    }
  }

  const addWhereToQuery = (query, filter) => {
    if(filter && filter.length == 3){
        return query.where(...filter)
    }
  }

  const onClick = (e, line) => {
    if(typeof setLine !== "function") return

    setLine(line)
    e.preventDefault()
  }

  return (
    <div className="space-y-8">
      {/* Action Buttons Section */}
      <div className="flex flex-col sm:flex-row gap-4 justify-center items-center relative">
        <button 
          onClick={onNewLine}
          className="flex items-center space-x-3 px-8 py-3 bg-[#6366F1] text-white rounded-xl hover:bg-[#5558E3] transition-colors"
        >
          <img src={arrow} alt="Arrow logo" className="w-6 h-6 brightness-0 invert opacity-80" />
          <span className="text-lg font-medium">Draw Racing Line</span>
        </button>
        
        <div className="flex flex-col items-center">
          <div className="flex items-center space-x-4">
            <span className="text-gray-500">or</span>
            <div className="flex items-center">
              <IRacingImport onImport={(points, carInfo, telemetryId) => {
                console.log("Import callback received points and car info:", { points, carInfo });
                setImportError(null); // Clear any previous errors
                setImportWarning(null); // Clear any previous warnings
                requireAuth(() => onImport({ points, carInfo, telemetryId }));
              }} />
            </div>
          </div>
        </div>
      </div>

      {/* Racing Lines Grid */}
      <div className={wrapperClass}>
        {lines && lines.map(line =>
          <article key={line.id} className="post-card track-card hover:shadow-lg transition-shadow">
            <div className="post-card-content track-card-content">    
              <a className="post-card-image-link track-card-image" href={`${line.url}/r/${line.id}`} onClick={e => onClick(e, line)}>
                <LineThumb points={line.points} width={200} height={130}></LineThumb>
              </a>
              <div className="p-4">
                <header className="post-card-header track-card-header">
                  {!filterTrack && <h2 className="post-card-title track-card-title font-bold text-lg">{line.name}</h2>}
                  <div className="text-sm text-gray-600">
                    {line.vehicle && line.vehicle != "" && <span>{line.vehicle} · </span>}
                    <span>{moment(line.created.toDate()).format("MMM, YYYY")}</span>
                    {showVisibility && <span className="ml-2 px-2 py-1 bg-gray-100 rounded-full text-xs">{line.visibility}</span>}
                  </div>
                </header>
                <footer className="post-card-meta mt-4 flex items-center justify-between">
                  {showProfilePhoto && <ProfileThumb uid={line.user} />}
                  {showLike && <LikeButton lineId={line.id}/>}
                </footer>
              </div>
            </div> 
          </article>
        )}
      </div>

      {/* Notifications positioned absolutely */}
      <div className="fixed left-1/2 transform -translate-x-1/2 top-32 w-full max-w-sm space-y-2 z-[9999]">
        {importError && (
          <Notification 
            message={importError}
            type="error"
            onClose={() => setImportError(null)}
          />
        )}
        {importWarning && (
          <Notification 
            message={importWarning}
            type="warning"
            onClose={() => setImportWarning(null)}
          />
        )}
      </div>
    </div>
  )
};
