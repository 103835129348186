import React, { useState, Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { X } from '@styled-icons/feather/X'

const Notification = ({ message, type = 'error', onClose }) => {
  const [isVisible, setIsVisible] = useState(true)

  const getColors = () => {
    switch (type) {
      case 'error':
        return {
          bg: 'bg-red-50',
          text: 'text-red-800',
          border: 'border-red-200'
        }
      case 'warning':
        return {
          bg: 'bg-yellow-50',
          text: 'text-yellow-800',
          border: 'border-yellow-200'
        }
      case 'success':
        return {
          bg: 'bg-green-50',
          text: 'text-green-800',
          border: 'border-green-200'
        }
      default:
        return {
          bg: 'bg-gray-50',
          text: 'text-gray-800',
          border: 'border-gray-200'
        }
    }
  }

  const { bg, text, border } = getColors()

  return (
    <Transition
      show={isVisible}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className={`rounded-lg p-4 ${bg} border ${border} flex items-center justify-between shadow-lg z-[9999]`}>
        <div className={`text-sm ${text}`}>
          {message}
        </div>
        <button
          onClick={() => {
            setIsVisible(false)
            onClose?.()
          }}
          className={`ml-4 ${text} hover:opacity-70 focus:outline-none`}
        >
          <X size={20} />
        </button>
      </div>
    </Transition>
  )
}

export default Notification 